/**
 * @see person/files/components/agreement/__tests__/util.ts
 *
 * jsdom defines window.scrollTo to be a function that outputs
 * "Not implemented", so `scrollTopWindow` must be mocked in tests.
 *
 * - Snapshot  -  1
 * + Received  + 16
 *
 * - Array []
 * + Array [
 * +   Array [
 * +     "Error: Not implemented: window.scrollTo
 * +     at module.exports (... member-forms/node_modules/jsdom/lib/jsdom/browser/not-implemented.js:9:17)
 * +     at scrollTo (... member-forms/node_modules/jsdom/lib/jsdom/browser/Window.js:866:7)
 * +     at scrollTopWindow (... member-forms/src/utils/scrollTopWindow.ts:5:12)
 * +     :
 * +     :
 * +     undefined,
 * +   ],
 * + ]
 */

import { navigationContainerId } from '@/components/componentIds'

/**
 * Scrolls the window to the top of the page. For side-nav pages, this
 * will scroll the page to the top of the content area although with the current structure
 * the content area is scrolled to the top of the page with the top nav bar overlaying what
 * is usually the page header (e.g. the persons name). This is fine because we're
 * scrolling to the top of the content area to see changes in the content which will be below this header
 *
 * COULD use `theme.mixins.toolbar` to get the height of the top nav bar and
 * scroll all content into the view but losing the header on the page also
 * seems fine
 */
export function scrollTopWindow(): void {
  // todo: this can probably be removed
  // because all sidenav pages have a non-scrolling header now
  // so this doesn't work.  However, it's still checked in the tests
  if (window && window.scrollTo) {
    window.scrollTo(0, 0)
  }

  const element = document?.getElementById(navigationContainerId)
  if (element) {
    element.firstElementChild?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
}
