import isSideNavEnabled from './isSideNavEnabled'
const v2Identifiers = ['/v2/', '/my-details', '/hr-services']

/**
 * Note: `useCurrentUrl` initally returns an empty string so can result in
 * flashes of V1 content before the V2 content is loaded.
 *
 * @param activeUrl Can be either a fully-qualified URL like
 * `https://app.myhr.works/v2/people` or a relative URL like `/hr-services or
 * /people/person/123`.
 * @returns true if the activeUrl is a v2 URL
 * @deprecated All V2 detection code should be removed.
 * @see [useCurrentUrl](./useCurrentUrl.ts)
 */
export const isV2Url = (activeUrl = ''): boolean =>
  isSideNavEnabled() ||
  v2Identifiers.some((identifier) => activeUrl.includes(identifier))

export const convertToV2Url = (isV2: boolean, url?: string): string =>
  url ? (isV2 && !isV2Url(url) ? `/v2${url}` : url) : ''
