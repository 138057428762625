import { isLoginButNotSecondStepPath } from '@/auth/loginFlowHelpers'
import { Member } from '@/types/Member'
import { MyHRPackage } from '@/types/multiple'
import { MyHR } from '@/types/MyHR'
import { NextPageContext } from 'next/dist/shared/lib/utils'
import { hasPermission } from './hasPermission'
import { RedirectError } from './RedirectError'
import isSideNavEnabled from './isSideNavEnabled'

/**
 * We now support billing for AU, NZ and CA so this function will
 * return true if a member's billing location matches one of those, otherwise false.
 * @param member
 * @returns boolean
 */
function memberBillingLocationIsSupported(member: Member): boolean {
  return ['NZ', 'AU', 'CA'].includes(member.billing_location_country_iso)
}

export function automatedBillingAccountIsLocked(member: Member): boolean {
  return member.force_update_credit_card_details
}

/**
 * Redirect customers who have permission to handle billing to either
 * /manage-account/pay-now if their initial automated billing invoice
 * is not yet paid or /manage-account/billing-details if their first invoice
 * has already been paid but their account has been manually locked.
 *
 * Throws redirection error if redirect is needed.
 */
export function billingRedirection(
  path: string,
  myhr: MyHR,
  ctx?: NextPageContext
) {
  const user = myhr?.user
  const payNowPath = isSideNavEnabled()
    ? '/settings/plan-billing/pay-now'
    : '/manage-account/pay-now'
  const billingDetailsPath = isSideNavEnabled()
    ? '/settings/plan-billing'
    : '/manage-account/billing-details'
  // Check the user has capability to pay the account and including only manager and
  // owner to allow admin role users so we can masquerade and do thing when bill has not been paid.
  if (
    !isLoginButNotSecondStepPath(path) &&
    ['manager', 'owner'].includes(user?.role) &&
    hasPermission(myhr, 'billing_update')
  ) {
    if (
      path !== payNowPath &&
      initialAutomatedBillingInvoiceHasNotBeenPaid(myhr?.member, myhr?.package)
    ) {
      throw new RedirectError('isBillPaidRedirectToNextUrl').setRedirection({
        ctx,
        nextUrl: payNowPath,
      })
    } else if (
      path !== billingDetailsPath &&
      initialAutomatedBillingInvoiceHasBeenPaid(myhr?.member, myhr?.package) &&
      automatedBillingAccountIsLocked(myhr?.member)
    ) {
      throw new RedirectError(
        'isAccountLockedRedirectToNextUrl'
      ).setRedirection({
        ctx,
        nextUrl: billingDetailsPath,
      })
    }
  }
}

export function initialAutomatedBillingInvoiceHasNotBeenPaid(
  member: Member,
  pkg: MyHRPackage
): boolean {
  return (
    !member.dps_customer_id &&
    !pkg.is_free &&
    memberBillingLocationIsSupported(member)
  )
}

/**
 * The member is on automated billing, their package is not free
 * and their payment system (Stripe) customer id HAS been set.
 * member.dps_customer_id is set on first payment.
 * @param member
 * @param pkg
 * @returns boolean
 */
export function initialAutomatedBillingInvoiceHasBeenPaid(
  member: Member,
  pkg: MyHRPackage
): boolean {
  return (
    !!member.dps_customer_id && // this is set upon first paid invoice in our payment system (now Stripe)
    !pkg.is_free
  )
}
