import styled from '@/utils/styled'
import { getPkgVersion } from '@/utils/getPkgVersion'
import { MyHRLayout, useLayout } from '@/components/contexts/useLayout'
import { Logo } from '@/components/layout/Logo'
import Grid from '@mui/material/Grid'
import { scrollTopWindow } from '@/utils/scrollTopWindow'
const version = getPkgVersion()

export const footerId = 'footer'

// Using a div so that the custom properties are not passed on
const FooterBox = styled('div')<{ layout?: MyHRLayout }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: ${(props) => props.theme.spacing(8, 0, 4)};
  padding: ${(props) => props.theme.spacing(1, 0)};
  border-top: ${(props) =>
    props.layout === 'basic'
      ? 'none'
      : `1px solid ${props.theme.palette.border.light}`};
  color: ${(props) => props.theme.palette.text.disabled};

  .myhr-logo {
    width: auto;

    svg {
      width: 70px;
      height: auto;
    }
  }

  p,
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: ${(props) => props.theme.spacing(1, 0)};
    padding: 0;
    font-size: ${(props) => props.theme.typography.body2.fontSize};
    line-height: 1.25;
    /* center alignment */
    margin: auto;
  }

  p {
    gap: ${(props) => props.theme.spacing(1)};
  }

  ul {
    gap: ${(props) => props.theme.spacing(2)};
    align-items: center;
    /* Safari respects flex-end but only Chrome/Edge respect end  */
    justify-content: flex-end;

    li {
      display: block;
      flex: 0 1;
      padding: 0;
      white-space: nowrap;

      a {
        color: ${(props) => props.theme.palette.text.disabled};
        text-decoration: none;

        &:hover {
          color: ${(props) =>
            props.layout === 'basic'
              ? props.theme.palette.common.white
              : props.theme.palette.text.primary};
          /* Override a:not(.btn):not(.nav-link):not(.card):hover { opacity: 0.5 }*/
          opacity: 1 !important;
        }
      }
    }
  }
`

type FooterProps = {
  displayPoweredBy?: boolean
}

export const Footer = ({ displayPoweredBy }: FooterProps): JSX.Element => {
  const copy = new Date().getFullYear()
  const [layout] = useLayout()

  return (
    <Grid container>
      <Grid item xs={12}>
        <FooterBox id={footerId} layout={layout}>
          <ul>
            <li>
              <a href="https://knowledge.myhr.works/terms-and-conditions">
                Terms &amp; Conditions
              </a>
            </li>
            <li>
              <a href="https://knowledge.myhr.works/privacy">Privacy Policy</a>
            </li>
            <li>
              <a href="https://knowledge.myhr.works/website-terms-of-use">
                Terms of Use
              </a>
            </li>
            <li>&copy;{copy} MyHR Ltd</li>
            <li onClick={() => scrollTopWindow()} style={{ cursor: 'pointer' }}>
              v.{version}
            </li>
          </ul>
          {displayPoweredBy && (
            <p>
              <span>Powered by</span>
              <span>
                <Logo color="blue" />
              </span>
            </p>
          )}
        </FooterBox>
      </Grid>
    </Grid>
  )
}
