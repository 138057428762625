import { useRouter } from 'next/router'

export const useIsSelfServiceSite = () => {
  const router = useRouter()
  // NB: in tests that aren't based on storybook, router is undefined
  return Boolean(
    router &&
      (router.asPath.match(/^\/self-service/) ||
        router.asPath.match(/^\/v2\/self-service/))
  )
}
