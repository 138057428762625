// Material helpers

import { createTheme } from '@mui/material/styles'

import palette from './palette'
import typography from './typography'
import { interFont } from '../GlobalStyle'
import { ComponentColors } from './styles/colors'

const theme = createTheme({
  typography: {
    ...typography,
    fontFamily: interFont,
  },
  palette,
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: `${ComponentColors.border['primary']}`,
        },
      },
    },
  },
  zIndex: {
    // @see https://v5-0-6.mui.com/customization/z-index/
    drawer: 1200 + 20000,
    modal: 1300 + 20000,
    snackbar: 1400 + 20000,
    tooltip: 1500 + 20000,
  },
})

export default theme
